import React from 'react';
import './Menu.scss'
import { NavLink } from "react-router-dom";
import logo from '../../../assets/images/logo.svg'; // with import


export default function Menu () {

    return (
        <nav className="c-menu">

            <NavLink
                to="/favorites" activeClassName="c-menu__link--active"
                className="c-menu__link b-icon b-icon--s icon-heart justify-content-center"/>
            <NavLink to="/" activeClassName="c-menu__link--active"
                     className="c-menu__link b-text-logo"><img src={logo} width="112" alt=""/></NavLink>
            <div className="d-flex justify-content-center">
                <NavLink to="/profile" activeClassName="c-menu__link--active"
                         className={"c-menu__link b-icon b-icon--s icon-profile-user"}/>
            </div>

        </nav>
    );

}
