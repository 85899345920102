import React, { useContext, useEffect, useRef } from 'react';
import './ErrorHandler.scss'
import { Toast } from 'primereact/toast';
import { ToastContext } from "../../../shared/contexts/ToastContenxt";
import { API } from "../../../shared/consts/api.const";

export default function ErrorHandler () {
    const toastRef = useRef(null);

    const { setToast } = useContext(ToastContext);

    useEffect(() => {
        setToast(toastRef);


        API.interceptors.response.use((response) => {
            // Do something with response data
            return response;
        }, (error) => {
            // Do something with response error
            let summary = '';
            let detail = '';
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors  && error.response.data.errors.msg) {
                summary = typeof error.response.data.errors.msg === 'string' ? 'Error' : error.response.data.errors.msg[0].param;
                detail = typeof error.response.data.errors.msg === 'string' ? error.response.data.errors.msg : error.response.data.errors.msg[0].msg
            } else if(error && error.response){
                summary = error.response.statusText;
                detail = error.response.status;
            }

            toastRef.current.show({
                severity: 'error',
                summary,
                detail,
                life: 3000
            });

            return Promise.reject(error);
        });

    }, [])


    return (
        <>
            <Toast className="g-primereact-toast" ref={toastRef}/>
        </>
    );

}
