import React from 'react';
import SoundGallery from "./components/SoundGallery/SoundGallery";
import Filtersounds from "./components/FilterSounds/FilterSounds";

export default function HomePage (props) {


    return (
        <div>
            <Filtersounds/>
            <SoundGallery/>
        </div>
    )
}
