import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import { API } from "../../shared/consts/api.const";
import { ToastContext } from "../../shared/contexts/ToastContenxt";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function ForgotPasswordPage () {
    const { register, handleSubmit } = useForm();

    const { toast } = useContext(ToastContext);
    const { t } = useTranslation();
    const history = useHistory();

    const forgotPassword = async (formData) => {
        await API.post('forgot', {email: formData.email});
        toast.current.show({
            severity: 'info',
            summary: t('Email sent'),
            detail: t('Check email inbox to check your forgot password'),
            life: 3000
        });
        history.push('login')
    }

    return (
        <form onSubmit={handleSubmit(forgotPassword)}>
            <div className="py-4">
                <label className="b-text-label" htmlFor="email">{t('Email')}</label>
                <input className="b-input" id="email" type="text" name="email"

                       ref={register}/>
            </div>

            <input className="b-btn b-btn--sec mt-4" type="submit" value={t('Confirm')}/>
        </form>
    )
}
