import React from "react";
import { useTranslation } from "react-i18next";

export default function ChangeLanguage () {
    const { i18n } = useTranslation();


    const changeLanguage = code => {
        i18n.changeLanguage(code);

        document.cookie = 'language=' + code;
    };

    return (
        <div className="row">
            <div className="offset-3 col-3 col-sm-2 offset-sm-4  col-lg-1  offset-lg-5">
                <img className="u-cursor-pointer" src="icons/spain.svg" alt="" onClick={() => changeLanguage('es')}/>
            </div>
            <div className="col-3 col-sm-2 col-lg-1">
                <img className="u-cursor-pointer" src="icons/united-kingdom.svg" alt=""
                     onClick={() => changeLanguage('en')}/>
            </div>
        </div>
    )

}
