import React, { useContext, useState } from 'react';
import { CounterContext } from "../../../shared/contexts/CounterContext";
import { Dialog } from 'primereact/dialog';
import SoundList from "./components/SoundList";
import { API } from "../../../shared/consts/api.const";
import { JwtContext } from "../../../shared/contexts/JwtContext";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { Slider } from "primereact/slider";
import { useTranslation } from "react-i18next";
import './ManageSounds.scss'

export default function ManageSounds () {

    const { t } = useTranslation();
    const { soundsList, activeSoundsCount, generalVolume, setGeneralVolume } = useContext(CounterContext);
    const { jwt, user, setUser } = useContext(JwtContext);
    const { register, handleSubmit } = useForm();


    const [activeDialog, setActiveDialog] = useState();

    const history = useHistory();
    const saveComb = async (formData) => {
        const { data } = await API.patch('profile', {
            favoritesSounds: {
                ...user.favoritesSounds,
                [formData.combName]: soundsList.filter(sound => sound.active)
            }
        })
        setUser(data);
        document.cookie = 'user=' + JSON.stringify(data);
        setActiveDialog(false);
        history.push('/favorites');
    }

    const soundRemoved = () => {
        if (activeSoundsCount === 1) {
            setActiveDialog(false);
        }
    }

    return (
        <>

        <span className="u-cursor-pointer b-icon b-icon--s icon-volume ml-5"
              onClick={() => setActiveDialog(!activeDialog)}>
            <span className="b-icon__badge">{activeSoundsCount}</span>
        </span>

            <Dialog className="g-primereact-dialog" visible={activeDialog}
                    onHide={setActiveDialog}>
                <div className="p-4 px-5">


                    <SoundList fnSoundRemoved={soundRemoved}/>


                    <div className="my-8">
                        <label htmlFor="generalVolume">{t('GENERAL_VOLUME')}</label>

                        <div className="mx-4 my-6">
                            <Slider id="generalVolume" className="g-primereact-slider g-primereact-slider--sec my-3"
                                    value={generalVolume}
                                    onChange={(e) => setGeneralVolume(e.value)} max={100} min={0}/>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(saveComb)}
                          className={(!soundsList.length || !jwt ? ' u-disabled' : '')}>

                        <div className="py-4">
                            <label htmlFor="combName">{t('COMB_NAME')}</label>
                            <input id="combName" className="b-input" type="text" name="combName"
                                   ref={register({ required: true })}/>
                            {/*<input id="combName" className="b-input" type="text" name="combName" placeholder={t('SAVE_THIS_COMBINATION')}*/}
                            {/*       ref={register({ required: true })}/>*/}
                        </div>
                        <button
                            className={"mt-4 b-btn b-btn--sec"}>{t('SAVE_ON_FAVORITES')}
                        </button>

                    </form>
                    {!jwt && <p className="u-text-align-center"><Link className="b-text b-text--important"
                                                                      to="/register">{t('Create an' +
                        ' account')}</Link> {t('to save this combination')}.</p>}
                </div>
            </Dialog>
        </>
    )
}
