import React from 'react';
import { Route, Switch, } from "react-router-dom";
import HomePage from "../../pages/HomePage/HomePage";
import LoginPage from "../../pages/LoginPage/LoginPage";
import RegisterPage from "../../pages/RegisterPage/RegisterPage";
import FavoritesPage from "../../pages/FavoritesPage/FavoritesPage";
import ProfilePage from "../../pages/ProfilePage/ProfilePage";
import ProfileEditPage from "../../pages/ProfilePage/pages/ProfileEditPage/ProfileEditPage";
import VerifyPage from "../../pages/VerifyPage/VerifyPage";
import ForgotPasswordPage from "../../pages/ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "../../pages/ResetPasswordPage/ResetPasswordPage";

export default function Routes () {

    return (
        <Switch>
            <Route path="/login">
                <LoginPage/>
            </Route>
            <Route path="/register">
                <RegisterPage/>
            </Route>
            <Route path="/forgot-password">
                <ForgotPasswordPage/>
            </Route>
            <Route path="/reset/:idUser">
                <ResetPasswordPage/>
            </Route>
            <Route path="/favorites">
                <FavoritesPage/>
            </Route>
            <Route path="/profile/edit">
                <ProfileEditPage/>
            </Route>
            <Route path="/profile">
                <ProfilePage/>
            </Route>

            <Route path="/verify/:idUser">
                <VerifyPage/>
            </Route>
            <Route path="/">
                <HomePage/>
            </Route>
        </Switch>
    )
}
