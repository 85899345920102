import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../shared/consts/api.const";
import { useTranslation } from "react-i18next";
import { ToastContext } from "../../shared/contexts/ToastContenxt";

export default function VerifyPage () {
    const { toast } = useContext(ToastContext);

    const { t } = useTranslation();

    let { idUser } = useParams();

    const history = useHistory();

    const verifyUser = () => {
        if (toast) {
            API.post('verify', { id: idUser }).then(() => {
                toast.current.show({
                    severity: 'success',
                    summary: t('Email confirmed'),
                    detail: t('Enjoy our app!'),
                    life: 3000
                });
                history.push('/');
            }).catch(() => {
                history.push('/');
            })
        }
    }
    // eslint-disable-next-line
    useEffect(verifyUser, [toast])


    return (
        <></>
    )
}
