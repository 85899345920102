import React, { useContext, useEffect, useRef } from 'react';
import { Slider } from 'primereact/slider';
import './SoundGallery.scss'
import { CounterContext } from "../../../../shared/contexts/CounterContext";

export default function SoundGallery () {
    const refIcon = useRef({});
    const { isPlaying, setIsPlaying, soundsList, setSoundsList, activeCategories } = useContext(CounterContext)


    const changeVolume = (e, index) => {
        const newSoundList = [...soundsList];

        newSoundList[index].volume = e.value;
        setSoundsList(newSoundList);
    }


    const playStopSound = (index) => {
        const actualIconRef = refIcon.current[index];
        const isActiveCurrentSound = actualIconRef.classList.contains('b-icon--active');
        const newSoundList = [...soundsList];

        newSoundList[index].active = !isActiveCurrentSound;
        setSoundsList(newSoundList);
    }

    const isReallyPlaying = () => {
        const anySoundActive = soundsList.find(sound => sound.active)
        if (anySoundActive || (anySoundActive && !isPlaying)) {
            setIsPlaying(true)
        } else if (!anySoundActive && isPlaying) {
            setIsPlaying(false)
        }
    }

    const checkAllSounds = () => {
        isReallyPlaying();

    }

    const showSound = (sound) => {
        let flagShowSound = false;
        for (const category of sound.categories) {
            if (activeCategories[category]) {
                flagShowSound = true;
                return flagShowSound;
            }
        }
        return flagShowSound;
    }

    useEffect(checkAllSounds, [soundsList])

    return (
        <div className="c-sound-gallery">
            <div className="row">
                {soundsList.map((sound, index) => (!activeCategories || (activeCategories && showSound(sound))) &&
                    <div className="col-6 col-md-4 col-lg-3" key={index}>
                        <div className="c-sound-gallery__item">


                            <span onClick={() => playStopSound(index)}
                                  className={`p-4 b-icon ${sound.active ? 'b-icon--active' : ''} ${sound.iconClass}`}
                                  ref={ref => {
                                      refIcon.current[index] = ref;
                                  }}/>
                            <div className="c-sound-gallery__balls">
                                {sound.categories.map((category, index) => <span key={index}
                                                                                 className="c-sound-gallery__ball mr-1"
                                                                                 style={{ backgroundColor: 'var(--color-category-' + category + ')' }}/>)}
                                <Slider
                                    className={`mt-2 g-primereact-slider ${sound.active ? 'g-primereact-slider--active' : ''}`}
                                    value={sound.volume}
                                    onChange={(e) => changeVolume(e, index)} max={100} min={0}/>
                            </div>
                        </div>

                    </div>
                )}
            </div>
        </div>
    )
}
