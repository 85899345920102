import React, { useContext } from 'react';
import { JwtContext } from "../../shared/contexts/JwtContext";
import SoundComb from "./components/SoundComb/SoundComb";
import { CounterContext } from "../../shared/contexts/CounterContext";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { API } from "../../shared/consts/api.const";
import { confirmDialog } from 'primereact/confirmdialog';

export default function FavoritesPage () {

    const { user, setUser } = useContext(JwtContext);
    const { setIsPlaying, soundsList, setSoundsList } = useContext(CounterContext)
    const { t } = useTranslation();

    const history = useHistory();

    const selectComb = (index) => {
        const newSoundsList = soundsList.map(sound => {
            const favoriteSound = user.favoritesSounds[index].find(favoriteSound => favoriteSound.id === sound.id);
            sound.active = !!favoriteSound;
            sound.volume = favoriteSound ? favoriteSound.volume : sound.volume;
            return sound;
        });
        setSoundsList(newSoundsList)
        setIsPlaying(true);
        history.push('/');
    }

    const confirmRemoveFavoriteSound = ($event, key) => {
        $event.stopPropagation();
        confirmDialog({
            message: t('Are you sure to delete this combination?'),
            header: t('Confirmation'),
            acceptLabel: t('Yes'),
            rejectLabel: t('No'),
            accept: () => {removeFavoriteSound(key)},
        });
    }

    const removeFavoriteSound = async (key) => {
        const userCopy = { ...user };
        delete userCopy.favoritesSounds[key];

        const { data } = await API.patch('profile', userCopy)
        setUser(data);
        document.cookie = 'user=' + JSON.stringify(data);
    }

    const renderFavoritesSounds = () => {

        const favoritesSoundsToRender = [];
        if (user) {
            for (const key in user.favoritesSounds) {
                favoritesSoundsToRender.push(<div onClick={() => {selectComb(key)}} key={key} className="my-4">
                    <h5 className="d-flex align-items-center">{key} <span
                        onClick={($event) => {confirmRemoveFavoriteSound($event, key)}}
                        className="u-cursor-pointer ml-3 b-icon b-icon--xxs icon-close"/>
                    </h5>
                    <SoundComb sounds={user.favoritesSounds[key]}/>
                </div>)
            }
        }

        return favoritesSoundsToRender.length ? favoritesSoundsToRender : user && user.name ? <p>{t("You don't" +
            " have any" +
            " combination saved")}.</p> : <p><Link className="b-text b-text--important" to="/register">{t('Create an' +
            ' account')}</Link> {t('to save a combination')}.</p>;
    }

    return (
        <div>
            {renderFavoritesSounds()}
        </div>
    )
}
