import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import { API } from "../../shared/consts/api.const";
import { ToastContext } from "../../shared/contexts/ToastContenxt";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";


export default function ResetPasswordPage () {
    const { register, handleSubmit } = useForm();

    const { toast } = useContext(ToastContext);
    const { t } = useTranslation();
    const history = useHistory();
    let { idUser } = useParams();


    const resetPassword = async (formData) => {
        await API.post('reset', { id: idUser, password: formData.password });
        toast.current.show({
            severity: 'info',
            summary: t('Password changed'),
            detail: t('You can login now with your new password'),
            life: 3000
        });
        history.push('/login')
    }

    return (
        <form onSubmit={handleSubmit(resetPassword)}>
            <div className="py-4">
                <label className="b-text-label" htmlFor="password">{t('Password')}</label>
                <input className="b-input" id="password" type="password" name="password"
                       ref={register}/>
            </div>

            <input className="b-btn b-btn--sec mt-4" type="submit" value={t('Confirm')}/>
        </form>
    )
}
