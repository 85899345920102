export const es = {
    'COMB_NAME': 'Nombre de la combinación',
    'GENERAL_VOLUME': 'Volumen general',
    'WELCOME': 'Bienvenido',
    'Sign out': 'Desconectar',
    'YOU_ARE_NOT_LOGGED_IN': 'No estás conectado',
    'ACTIVE_SOUNDS': 'Sonidos activos',
    'SAVE_THIS_COMBINATION': 'pon un nombre molón :D',
    'SAVE_ON_FAVORITES': 'Guardar en favoritos',
    "All": 'Todos',
    "Popular": 'Popular',
    "City": 'Ciudad',
    'Home': 'Casa',
    'Nature': 'Naturaleza',
    'Motor': 'Motor',
    'Noise': 'Ruido',
    'ASMR': 'ASMR',
    'Sign in': 'Registrarse',
    'Log in': 'Iniciar sesión',
    'Change language': 'Cambiar idioma',
    'Try clicking any icon': 'Haz click en un icono',
    'Name': 'Nombre',
    'Email': 'Email',
    'Password': 'Contraseña',
    'Send': 'Enviar',
    'Privacy Policy': 'Política de privacidad',
    'You don\'t have any combination saved': 'No tienes ninguna combinación guardada',
    'Are you sure to delete this combination?': '¿Estás seguro de eliminar esta combinación?',
    'Confirmation': 'Confirmación',
    'Confirm': 'Confirmar',
    'Yes': 'Si',
    'No': 'No',
    'Create an account to save sounds combinations': 'Crea una cuenta para guardar combinaciones de sonidos',
    'Edit user': 'Editar usuario',
    'Save': 'Guardar',
    'Edit': 'Editar',
    'Email confirmed': 'Email confirmado',
    'Enjoy our app!': 'Disfruta nuestra app!',
    'Confirm your email': 'Confirma tu email',
    'Your account was created successfully but you need to confirm it through the email we have sent you': 'Tu cuenta' +
        ' fue' +
        ' creada correctamente pero necesitas confirmarla mediante el email que te hemos enviado',
    'Forgot password': 'Contraseña olvidada',
    'Email sent': 'Email enviado',
    'Check email inbox to see your forgot password': 'Revisa tu email para ver tu contraseña',
    'Password changed': 'Contraseña cambiada',
    'You can login now with your new password': 'Puedes loguearte con tu nueva contraseña',
    'Start': 'A contar',
    'Create an account': 'Crea una cuenta',
    'to save this combination': 'para guardar esta combinación',
    'to save a combination': 'para guardar una combinación'
}
