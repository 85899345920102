import React, { useContext, useEffect } from 'react';
import { CounterContext } from "../../../../shared/contexts/CounterContext";
import { categoriesConst } from "../../../../shared/consts/categories.const";
import './FilterSounds.scss';
import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
import { randomSoundsConst } from "../../../../shared/consts/randomSounds.const";
import { useTranslation } from "react-i18next";

let randomSoundsConstDisordered = [];
export default function Filtersounds () {

    const { activeCategories, setActiveCategories, soundsList, setSoundsList } = useContext(CounterContext);
    const { t } = useTranslation();

    const filterByCategory = (index) => {
        if (index !== null) {
            const newActiveCategories = { ...activeCategories };
            newActiveCategories[categoriesConst[index].name] = !newActiveCategories[categoriesConst[index].name];
            setActiveCategories(newActiveCategories);
        } else {
            setActiveCategories(null);
        }
    }

    const playRandomSounds = () => {
        const randomSoundsList = getDisorderedSound();

        const newSoundsList = soundsList.map(sound => {
            const randomFound = randomSoundsList.find(randomSound => randomSound.id === sound.id)
            if (randomFound) {
                sound.active = true;
                sound.volume = randomFound.volume;
            } else {
                sound.active = false;
            }
            return sound
        });

        setSoundsList(newSoundsList);
    }

    const getDisorderedSound = () => {
        if (!randomSoundsConstDisordered.length) {
            randomSoundsConstDisordered = [...randomSoundsConst].sort(function () {return Math.random() - 0.5});
        }
        const soundToReturn = randomSoundsConstDisordered[0];
        randomSoundsConstDisordered.splice(0, 1);
        return soundToReturn;
    }

    useEffect(() => {
        // setTimeout(() =>{

        const swiper = new Swiper('.swiper-container', {
            slidesPerView: 'auto',
            spaceBetween: 10,
            allowTouchMove: true,
            loop: false,
            freeMode: true,
            centeredSlides: false
        });
        setTimeout(() => {
            swiper.update();
        }, 500)
    }, [])

    return (
        <div className="c-filter-sounds">

        <div className="d-flex">
            <div onClick={playRandomSounds}
                 className={`b-bubble`} style={{ backgroundColor: 'var(--color-secondary)' }}>
                <span className="b-icon b-icon--xxs icon-shuffle"/>
            </div>

            <span className="d-flex align-items-center c-filter-sounds__border mx-4"/>

            <div className="swiper-container u-width-100">
                <div className="swiper-wrapper">

                    <button onClick={() => {filterByCategory(null)}}
                            style={{ backgroundColor: 'var(--color-secondary)' }}
                            className={`swiper-slide b-bubble  ${!activeCategories ? 'b-bubble--active' : ''}`}>
                        {t('All')}
                    </button>
                    {categoriesConst.map((category, index) =>
                        <div className="swiper-slide" key={index}>
                            <button onClick={() => {filterByCategory(index)}}
                                    style={{ backgroundColor: 'var(--color-category-' + category.name + ')' }}
                                    className={`b-bubble ${activeCategories && activeCategories[category.name] ? 'b-bubble--active' : ''}`}>
                                {t(category.name)}
                                <span className={`b-bubble__icon b-icon b-icon--xxs ml-3 ${category.iconClass}`}/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>);
}
