import React from 'react';

export default function SocialAuth () {
    return (
        <div className="d-flex justify-content-center">
            <a href={process.env.REACT_APP_BACK_URL + "auth/facebook"} className="mr-4"><img
                className="b-badge b-badge--facebook"
                src="icons/facebook.svg" alt="facebook"/></a>
            <a href={process.env.REACT_APP_BACK_URL + "auth/google"}><img className="b-badge" src="icons/google.svg"
                                                                          alt="google"/></a>
        </div>
    )
}
