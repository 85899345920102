export const randomSoundsConst = [
    [
        { id: "15", volume: 50 },
        { id: "9", volume: 50 }
    ], [
        { id: "5", volume: 25 },
        { id: "18", volume: 30 }
    ], [
        { id: "2", volume: 50 },
        { id: "10", volume: 25 },
        { id: "12", volume: 10 }
    ], [
        { id: "14", volume: 25 },
        { id: "13", volume: 15 }
    ]

    , [
        { id: "23", volume: 35 },
        { id: "24", volume: 50 }
    ]
    , [
        { id: "25", volume: 50 },
        { id: "27", volume: 50 }
    ]
    , [
        { id: "9", volume: 50 },
        { id: "22", volume: 25 }
    ]
    , [
        { id: "14", volume: 25 },
        { id: "13", volume: 15 }
    ]  , [
        { id: "20", volume: 50 },
        { id: "13", volume: 15 },
        { id: "25", volume: 20 }
    ]
]
