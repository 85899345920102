import React, { useEffect, useState } from 'react';
import './SoundComb.scss';

export default function SoundComb ({ sounds }) {

    const [totalSound, setTotalSound] = useState(0)

    useEffect(() => {
        setTotalSound(sounds.reduce((total, sound) => total + sound.volume, 0));
    }, [sounds])

    return (
        <div className="c-sound-comb">
            {sounds.map((sound, index) => <div key={index} style={{
                borderRadius: index === 0 ? (sounds.length === 1 ? '2.5rem' : '2.5rem 0 0  2.5rem') : (index === sounds.length - 1 ? '0 2.5rem' +
                    ' 2.5rem' +
                    ' 0' : '0'),
                backgroundColor: 'var(--color-category-' + sound.categories[sound.categories.length - 1] + ')',
                width: (sound.volume / totalSound ) * 100 + '%'
            }} className={"c-sound-comb__sound" + (sounds.length - 1 === index ? ' c-sound-comb__sound--last' : '')}>
                <span className={`b-icon b-icon--xxs ${sound.iconClass}`}/></div>)}
        </div>
    )
}
