import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { JwtContext } from "../../../../shared/contexts/JwtContext";
import { removeCookieUtil } from "../../../../shared/utils/removeCookieUtil";
import { useTranslation } from "react-i18next";

export default function SignOutButton () {
    const { jwt, setJwt, user, setUser } = useContext(JwtContext);
    let history = useHistory();
    const { t } = useTranslation();

    const signOut = () => {
        removeCookieUtil('user');
        removeCookieUtil('token');

        setJwt(null);
        setUser(null);
        history.push("/login");
    }

    return jwt ? (
        <p>
            {t('WELCOME')}! {user.name}
            <button className="mt-4 b-btn b-btn--third"
                    onClick={() => { history.push("/profile/edit");}}>
                {t('Edit user')}
            </button>
            <button className="mt-4 b-btn b-btn--sec"
                    onClick={signOut}
            >
                {t('Sign out')}
            </button>
        </p>
    ) : (
        <p>{t('YOU_ARE_NOT_LOGGED_IN')}.</p>
    );
}
