import React, { useContext, useEffect, useRef } from 'react';
import { Calendar } from 'primereact/calendar';
import { CounterContext } from "../../../shared/contexts/CounterContext";
import { useTranslation } from "react-i18next";

let interval;

const defaultTimesInMinutes = [{minutes: 2, text: '2m'},{minutes: 5, text: '5m'},{minutes: 10, text: '10m'},{minutes: 15, text: '15m'},{minutes: 30, text: '30m'},{minutes: 45, text: '45m'},{minutes: 60, text: '1h'},{minutes: 120, text: '2h'}];

export default function Counter () {

    const { counter, setCounter, isPlaying, setIsPlaying, soundsList } = useContext(CounterContext);

    const calendarRef = useRef(null);
    const { t } = useTranslation();

    const startCounter = () => {
        if (isCounterZero(counter)) {
            stopCounter();
        } else {
            calendarRef.current.hideOverlay(); // hide calendar popup
            setIsPlaying(true);

            interval = setInterval(() => {
                setCounter(counter => {
                    const newDate = new Date(counter.getTime());
                    newDate.setSeconds(counter.getSeconds() - 1)
                    return newDate
                })
            }, 1000)
        }
    }


    const stopCounter = () => {
        setIsPlaying(false);
        clearInterval(interval);
    }

    const addMinutesToCounter = (minutes) => {
        setCounter(counter => {
            const localHours = minutes >= 60 ? Math.trunc(minutes / 60) : 0
            const localMinutes = minutes < 60 ? minutes : minutes % 60;
            const localSeconds = 0;
            const newDate = new Date(counter.getTime());
            newDate.setHours(localHours);
            newDate.setMinutes(localMinutes);
            newDate.setSeconds(localSeconds);
            calendarRef.current.updateInputfield(newDate);
            return newDate;
        });
    }

    useEffect(() => {
        if (!isPlaying) {
            stopCounter();
        }
    }, [isPlaying])


    useEffect(() => {
        if (isCounterZero(counter)) {
            stopCounter();
        }
    }, [counter])




    const footerTemplate = () => <div>
        <div className="d-flex flex-wrap justify-content-center mt-6 mb-2">
            {defaultTimesInMinutes.map((timeInMinutes, index) =>
                <button onClick={() => {addMinutesToCounter(timeInMinutes.minutes)}} key={index} className="b-bubble b-bubble--min-width-big m-1">{timeInMinutes.text}</button>
            )}
        </div>
        <button onClick={startCounter}
                className={'b-btn b-btn--sec mt-4' + (!soundsList.length ? ' u-disabled' : '')}>{t('Start')}!
        </button>
    </div>

    return (
        <div className="d-flex justify-content-center">
            <Calendar ref={calendarRef} footerTemplate={footerTemplate}
                      className="g-primereact-calendar g-primereact-calendar--timer"
                      timeOnly showSeconds showTime hourFormat="24" readOnlyInput={true}
                      value={counter} onChange={(e) => setCounter(e.value)}/>
        </div>
    )
}

const isCounterZero = (counter) => {
    const seconds = counter.getSeconds();
    const minutes = counter.getMinutes();
    const hours = counter.getHours();
    return hours === 0 && minutes === 0 && seconds === 0;
}
