import React, { useContext, useEffect, useRef } from 'react';
import Counter from "../Counter/Counter";
import './SoundControlMenu.scss';
import { CounterContext } from "../../../shared/contexts/CounterContext";
import ManageSounds from "../ManageSounds/ManageSounds";
import { useTranslation } from "react-i18next";

export default function SoundControlMenu () {
    const refActiveSounds = useRef([]);
    const { t } = useTranslation();

    const { isPlaying, setIsPlaying, activeSoundsCount, setActiveSoundsCount, soundsList, setSoundsList, generalVolume } = useContext(CounterContext);


    const playPause = () => {
        setIsPlaying(!isPlaying)
    }

    const removeAllPlayingSound = () => {
        const newSoundsList = soundsList.map(sound => {
            sound.active = false;
            return sound;
        })
        setSoundsList(newSoundsList);
        setIsPlaying(false);
        setActiveSoundsCount(0);
    }

    const setActualActiveSoundConfig = () => {
        let activeSoundsCount = 0;
        for (let i = 0; i < soundsList.length; i++) {
            let sound = soundsList[i];
            const refActiveSound = refActiveSounds.current[i];
            if (refActiveSound) {
                refActiveSound.volume = (sound.volume / 100) * (generalVolume / 100);
                refActiveSound.play();
            }
            if (sound.active) {
                activeSoundsCount++;
            }

        }
        if (activeSoundsCount > 0) {
            setIsPlaying(!!activeSoundsCount)
            setActiveSoundsCount(activeSoundsCount)
        } else {
            setActiveSoundsCount(0)
        }
    }

    useEffect(() => {
        setActualActiveSoundConfig();
    }, [soundsList])


    return (
        <nav className={`c-sound-control-menu  ${activeSoundsCount === 0 ? 'c-sound-control-menu--no-sounds' : ''}`}>

            <div className={`c-sound-control-menu__grid`}>
                <div className="d-flex justify-content-center">
                    <ManageSounds/>
                </div>
                <Counter/>
                <div className="d-flex justify-content-center">
                    <span onClick={playPause}
                          className={'u-cursor-pointer b-icon b-icon--xs mr-3' + (isPlaying ? ' icon-pause' : ' icon-play') + (!activeSoundsCount ? ' u-disabled' : '')}/>
                    <span onClick={removeAllPlayingSound}
                          className={'u-cursor-pointer b-icon b-icon--xs icon-cancel' + (!activeSoundsCount ? ' u-disabled' : '')}/>
                </div>
                <div>
                    {soundsList.map((sound, index) =>
                        isPlaying && sound.active &&
                        <audio key={index} autoPlay id={sound.id} className="c-sound-gallery__audio"
                               ref={ref => {
                                   if (ref) ref.volume = (sound.volume / 100) * (generalVolume / 100);
                                   refActiveSounds.current[index] = ref;
                               }}
                               src={`sounds/${sound.audioName}.${sound.format}`} loop/>
                    )}
                </div>
            </div>
            <div
                className={`c-sound-control-menu__grid c-sound-control-menu__grid--sec`}>
                <p className="d-flex justify-content-center">{t('Try clicking any icon')}!</p>
            </div>
        </nav>
    )
}
