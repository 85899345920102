export const en = {
    'COMB_NAME': 'Combination name',
    'GENERAL_VOLUME': 'General volume',
    'WELCOME': 'Welcome',
    'SIGN_OUT': 'Sign out',
    'YOU_ARE_NOT_LOGGED_IN': 'You are not logged in',
    "ACTIVE_SOUNDS": 'Active sounds',
    'SAVE_THIS_COMBINATION': 'insert a nice name :D',
    'SAVE_ON_FAVORITES': 'Save on favorites',
    'CATEGORIES': {
        "ALL": 'All',
        "POPULAR": 'Popular',
        "CITY": 'City',
        'HOME': 'Home',
        'NATURE': 'Nature',
        'MOTOR': 'Motor',
        'NOISE': 'Noise'
    }
}
