import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import { API } from "../../shared/consts/api.const";
import { useHistory } from "react-router-dom";
import { ToastContext } from "../../shared/contexts/ToastContenxt";
import { useTranslation } from "react-i18next";
import SocialAuth from "../../shared/components/SocialAuth/SocialAuth";

export default function RegisterPage () {

    const { toast } = useContext(ToastContext);
    const { register, handleSubmit } = useForm();
    const { t } = useTranslation();

    const history = useHistory()

    const doRegister = async formData => {
        await API.post('register', { ...formData });
        toast.current.show({
            severity: 'info',
            summary: t('Confirm your email'),
            detail: t('Your account was created successfully but you need to confirm it through the email we have' +
                ' sent' +
                ' you'),
            life: 3000
        });
        history.push('/login');
    }

    return (
        <>
            <div className="my-4">
                <SocialAuth/>
            </div>
            <div className="d-flex justify-content-center">
                -o-
            </div>
            <form onSubmit={handleSubmit(doRegister)}>
                <div className="py-4">
                    <label className="b-text-label" htmlFor="name">Name</label>
                    <input className="b-input" id="name" type="text" name="name" ref={register}/>
                </div>
                <div className="py-4">
                    <label className="b-text-label" htmlFor="email">Email</label>
                    <input className="b-input" id="email" type="text" name="email"
                           ref={register}/>
                </div>
                <div className="py-4">
                    <label className="b-text-label" htmlFor="password">Password</label>
                    <input className="b-input" id="password" type="password" name="password"
                           ref={register}/>
                </div>
                <input className="b-btn b-btn--sec mt-4" type="submit" value="Send"/>
            </form>
        </>
    )
}
