import React, { useState } from 'react';
import './App.css';
import Routes from "./core/Routes/Routes";
import { BrowserRouter as Router, } from "react-router-dom";
import { CounterContext } from "./shared/contexts/CounterContext";
import { JwtContext } from './shared/contexts/JwtContext';
import { getCookieUtil } from "./shared/utils/getCookieUtil";
import SoundControlMenu from "./core/components/SoundControlMenu/SoundControlMenu";
import Menu from "./core/components/Menu/Menu";
import { soundsConst } from "./shared/consts/sounds.const";
import { ToastContext } from "./shared/contexts/ToastContenxt";
import ErrorHandler from "./core/components/ErrorHandler/ErrorHandler";

function App () {
    const date = new Date();
    date.setHours(0);
    date.setMinutes(5);
    date.setSeconds(0);
    const [counter, setCounter] = useState(date);
    const [isPlaying, setIsPlaying] = useState(false);
    const [jwt, setJwt] = useState(getCookieUtil('token') || null);

    const unParsedUser = getCookieUtil('user');
    const [user, setUser] = useState(unParsedUser.length > 0 ? JSON.parse(unParsedUser) : {});
    const [activeCategories, setActiveCategories] = useState(null);
    // , {
    //     id: '2',
    //
    //     iconClass: 'icon-hairdryer',
    //     audioName: '2.dryer',
    //     volume: 50
    //
    // },
    const [soundsList, setSoundsList] = useState(soundsConst);
    const [toast, setToast] = useState(null);
    const [activeSoundsCount, setActiveSoundsCount] = useState(0);
    const [generalVolume, setGeneralVolume] = useState(50);

    return (
        <JwtContext.Provider value={{ jwt, setJwt, user, setUser }}>
            <Router>
                <ToastContext.Provider value={{ toast, setToast }}>
                    <ErrorHandler/>
                    <CounterContext.Provider
                        value={{
                            counter,
                            setCounter,
                            isPlaying,
                            setIsPlaying,
                            soundsList,
                            setSoundsList,
                            activeSoundsCount,
                            setActiveSoundsCount,
                            activeCategories,
                            setActiveCategories,
                            generalVolume,
                            setGeneralVolume
                        }}>
                        <div className="container pb-9">
                            <div className="px-4">
                                <Menu/>
                                <div className="pb-5 mb-5">
                                    <Routes/>
                                </div>
                                <SoundControlMenu/>
                            </div>
                        </div>
                    </CounterContext.Provider>
                </ToastContext.Provider>
            </Router>
        </JwtContext.Provider>
    );
}

export default App;
