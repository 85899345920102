import React, { useContext } from 'react';
import { useForm } from "react-hook-form";
import { API } from "../../shared/consts/api.const";
import { Link, useHistory } from "react-router-dom";
import { JwtContext } from "../../shared/contexts/JwtContext";
import { useTranslation } from "react-i18next";
import SocialAuth from "../../shared/components/SocialAuth/SocialAuth";

export default function LoginPage () {

    const { register, handleSubmit } = useForm();
    const { setJwt, setUser } = useContext(JwtContext);
    const { t } = useTranslation();
    const history = useHistory();

    const doLogin = async formData => {
        const res = await API.post('login', formData);

        const expiration =  process.env.REACT_APP_JWT_EXPIRATION_IN_MINUTES * 60 ;

        document.cookie = 'token=' + res.data.token + ';max-age=' + expiration;
        document.cookie = 'user=' + JSON.stringify(res.data.user) + ';max-age=' + expiration;
        setJwt(res.data.token);
        setUser(res.data.user);
        history.push('/');
    }


    return (
        <>
            <div className="my-4">
                <SocialAuth/>
            </div>
            <div className="d-flex justify-content-center">
                -o-
            </div>
            <form onSubmit={handleSubmit(doLogin)}>
                <div className="py-4">
                    <label className="b-text-label" htmlFor="email">{t('Email')}</label>
                    <input className="b-input" id="email" type="text" name="email"

                           ref={register}/>
                </div>

                <div className="py-4">
                    <label className="b-text-label" htmlFor="password">{t('Password')}</label>
                    <input className="b-input" id="password" type="password" name="password"
                           ref={register}/>
                </div>
                <input className="b-btn b-btn--sec mt-4" type="submit" value={t('Send')}/>

                <div className="mt-4 d-flex justify-content-center">
                    <Link to="forgot-password">{t('Forgot password')}</Link>
                </div>

            </form>
        </>
    )
}
