import React, { useContext } from 'react';
import './SoundList.scss'
import { Slider } from "primereact/slider";
import { CounterContext } from "../../../../shared/contexts/CounterContext";
import { useTranslation } from "react-i18next";

export default function SoundList ({ fnSoundRemoved }) {

    const { t } = useTranslation();
    const { soundsList, setSoundsList } = useContext(CounterContext);

    const changeVolume = (e, index) => {
        const newSoundList = [...soundsList];

        newSoundList[index].volume = e.value;
        setSoundsList(newSoundList);
    }

    const removeActiveSound = (index) => {

        if (fnSoundRemoved) fnSoundRemoved();
        const newActiveSoundList = [...soundsList];
        newActiveSoundList[index].active = false;
        setSoundsList(newActiveSoundList)
    }

    return (

        <>
            <label htmlFor="activeSounds">{t('ACTIVE_SOUNDS')}</label>

            <div className="c-sound-list ">
                {soundsList.map((sound, index) => sound.active && <div className="c-sound-list__item" key={index}>
                        <span className={'b-icon b-icon--s b-icon--primary justify-content-center ' + sound.iconClass}/>
                        {/*<h4>{sound.audioName}</h4>*/}
                        <Slider className="g-primereact-slider g-primereact-slider--sec my-3" value={sound.volume}
                                onChange={(e) => changeVolume(e, index)} max={100} min={0}/>

                        <span onClick={() => {removeActiveSound(index)}} className={'c-sound-list__remove b-icon ' +
                        ' b-icon--primary' +
                        ' justify-content-center' +
                        ' icon-close'}/>
                    </div>
                )}
            </div>
        </>

    )
}
