export const soundsConst = [
    {
        id: '15',

        iconClass: 'icon-waves',
        audioName: '15.waves',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'mp3'
    },{
        id: '9',

        iconClass: 'icon-rain-1',
        audioName: '9.rain',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'mp3'
    }, {
        id: '33',

        iconClass: 'icon-storm1',
        audioName: '33.storm',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'mp3'
    },
     {
        id: '18',

        iconClass: 'icon-rainy-day',
        audioName: '18.34-rain',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'mp3'
    },
    {
        id: '19',
        iconClass: 'icon-storm',
        audioName: '19.storm',
        volume: 50,
        categories: ['Nature'],
        format: 'mp3'
    },
    {
        id: '13',

        iconClass: 'icon-river',
        audioName: '13.water',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'mp3'
    }, {
        id: '14',

        iconClass: 'icon-waterfall',
        audioName: '14.waterfall',
        volume: 50,
        categories: ['Nature'],
        format: 'mp3'
    },
    {
        id: '5',

        iconClass: 'icon-car-engine',
        audioName: '5.motor',
        volume: 50,
        categories: ['Popular', 'City'],
        format: 'mp3'
    },{
        id: '11',

        iconClass: 'icon-train',
        audioName: '11.train',
        volume: 50,
        categories: ['Popular', 'City'],
        format: 'mp3'
    },
    // {
    //     id: '6',
    //
    //     iconClass: 'icon-waves',
    //     audioName: '6.ocean-1',
    //     volume: 50,
    //     categories: ['Popular', 'Nature'],
    //     format: 'mp3'
    // }, {
    //     id: '7',
    //
    //     iconClass: 'icon-waves',
    //     audioName: '7.ocean-2',
    //     volume: 50,
    //     categories: ['Nature'],
    //     format: 'mp3'
    // },
 {
        id: '10',

        iconClass: 'icon-shower',
        audioName: '10.shower',
        volume: 50,
        categories: ['Home'],
        format: 'mp3'
    },  {
        id: '12',

        iconClass: 'icon-bubble',
        audioName: '12.underwater',
        volume: 50,
        categories: ['Nature'],
        format: 'mp3'
    },    {
        id: '8',

        iconClass: 'icon-sound-frecuency',
        audioName: '8.pink-noise',
        volume: 50,
        categories: ['Noise'],
        format: 'mp3'
    },  {
        id: '16',

        iconClass: 'icon-sound-frecuency',
        audioName: '16.white-noise-1',
        volume: 50,
        categories: ['Noise'],
        format: 'mp3'
    }, {
        id: '17',

        iconClass: 'icon-sound-waves',
        audioName: '17.white-noise-2',
        volume: 50,
        categories: ['Noise'],
        format: 'mp3'
    }, {
        id: '20',

        iconClass: 'icon-sun',
        audioName: '20.dd',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'mp3'
    }, {
        id: '21',

        iconClass: 'icon-frog',
        audioName: '21.db',
        volume: 50,
        categories: ['Nature'],
        format: 'mp3'
    }, {
        id: '22',

        iconClass: 'icon-coffee-cup',
        audioName: '22.fb',
        volume: 50,
        categories: ['Popular', 'City'],
        format: 'ogg'
    }, {
        id: '23',
        iconClass: 'icon-tent',
        audioName: '23.dc',
        volume: 50,
        categories: ['Nature'],
        format: 'ogg'
    }, {
        id: '24',

        iconClass: 'icon-bonfire',
        audioName: '24.ea',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'ogg'
    }, {
        id: '25',

        iconClass: 'icon-trees',
        audioName: '25.da',
        volume: 50,
        categories: ['Nature'],
        format: 'ogg'
    }, {
        id: '26',

        iconClass: 'icon-wind',
        audioName: '26.ce',
        volume: 50,
        categories: ['Popular', 'Nature'],
        format: 'ogg'
    }, {
        id: '27',

        iconClass: 'icon-campana-de-viento',
        audioName: '27.gb',
        volume: 50,
        categories: ['Nature'],
        format: 'ogg'
    }, {
        id: '28',

        iconClass: 'icon-gong',
        audioName: '28.ga',
        volume: 50,
        categories: ['Nature'],
        format: 'ogg'
    }, {
        id: '1',
        iconClass: 'icon-blender',
        audioName: '1.blender',
        volume: 50,
        categories: ['Home'],
        format: 'mp3'
    },
    {
        id: '3',
        iconClass: 'icon-fan',
        audioName: '3.fan',
        volume: 50,
        categories: ['Popular', 'Home'],
        format: 'mp3'
    }, {
        id: '4',
        iconClass: 'icon-air-conditioning',
        audioName: '4.heater',
        volume: 50,
        categories: ['Home'],
        format: 'mp3'
    }, {
        id: '29',
        iconClass: 'icon-potato-chips',
        audioName: '29.potato-chips',
        volume: 50,
        categories: ['Popular', 'ASMR'],
        format: 'mp3'
    }, {
        id: '30',
        iconClass: 'icon-sesame',
        audioName: '30.seed',
        volume: 50,
        categories: ['Popular', 'ASMR'],
        format: 'mp3'
    }, {
        id: '31',
        iconClass: 'icon-box',
        audioName: '31.box',
        volume: 50,
        categories: ['ASMR'],
        format: 'mp3'
    },{
        id: '32',
        iconClass: 'icon-chest-of-drawers',
        audioName: '32.drawer',
        volume: 50,
        categories: ['ASMR'],
        format: 'mp3'
    },
];
